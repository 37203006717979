import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {Data} from '@core/app/types/requestsApiType';


export const requestsApi = createApi({
    reducerPath: 'requestsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.ENV === 'local' ? `${process.env.API_URL}/api` : `${window.location.origin}/api`,
        prepareHeaders: (headers) => {
            const token = document.getElementsByName('csrf-token')[0]?.getAttribute('content');
            if (token) headers.set('X-CSRF-TOKEN', token);

            const customCorporation = document.getElementById('__custom_route_data')?.getAttribute('data-corpId');
            if (customCorporation) headers.set('X-CORP-CONTEXT', customCorporation);

            return headers;
        },
        credentials: process.env.ENV === 'local' ? 'include' : 'same-origin',
    }),
    tagTypes: ['Bookings', 'Projects', 'Currencies'],
    endpoints: (builder) => ({
        getBookings: builder.query<Data, { groupBy?: number; groupSort?: number; busy?: number; statuses?: number[]; projects?: number[] }>({
            query: (params) => ({
                url: '/employee/bookings',
                method: 'GET',
                params,
            }),
            transformResponse: (response: { statusCode: number; success: boolean; message: string; data: Data }) => response.data,
            providesTags: ['Bookings'],
        }),
        getAvailableCurrencies: builder.query<any, number>({
            query: (bookingId) => ({
                url: `/employee/booking/${bookingId}`,
                method: 'GET',
            }),
            transformResponse: (response: { statusCode: number; success: boolean; message: string; data: any }) => response.data,
            providesTags: ['Currencies'],
        }),
        getProjectsForFilter: builder.query<{ id: number; name: string; colorId: number; number: number }[], { search?: string }>({
            query: (params) => ({
                url: '/employee/booking/projects',
                method: 'GET',
                params,
            }),
            transformResponse: (response: { statusCode: number; success: boolean; message: string; data: any[] }) => response.data,
            providesTags: ['Projects'],
        }),
        acceptBooking: builder.mutation<void, { shiftIds: number[] }>({
            query: (body) => ({
                url: '/employee/booking/accept',
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['Bookings'],
        }),
    }),
});

export const {
    useGetBookingsQuery,
    useGetAvailableCurrenciesQuery,
    useGetProjectsForFilterQuery,
    useAcceptBookingMutation,
} = requestsApi;
